// scss-docs-start theme-color-variables
$primary:                       #ff6c00;
$primary-active:                #cc5500;


$card-header-py:                0;
$card-px:                       1.5rem;

$content-spacing: (
    desktop:                    15px,
    tablet-and-mobile:          10px
);


$input-btn-padding-y:           .6rem;
$card-header-height: 		    60px;
