.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}
.min-col {
    width: 1%;
}

.c-loader > .sk-double-bounce.is-loaded{
    opacity:0;
}


/* buscador header para cuando se minimiza el sidebar */


/* fix disabled */
input {
    &:disabled,
    &[readonly] {
        cursor : not-allowed;

        .form-check &.form-check-input {
            cursor         : not-allowed;
            pointer-events : all;
        }
    }
}


// Form control
.form-control {

    // Solid style
    &.form-control-solid {
        &:disabled,
        &[readonly] {
            @extend .bg-secondary;
            @extend .text-muted;
            @extend .border-secondary;

        }
    }

}

.multiselect{
    min-height: 39px;
    &.form-control{
        min-height: 37px;
        height: 37px;
    }
}
.multiselect__tags{
    min-height: 39px;
    padding: 0 40px 1px 8px;
    border: $input-border-width solid $input-border-color;
    .form-control > &{
        min-height: 37px;
        border:0;
    }
}
.multiselect__tags-wrap{
    display: inline-block;
    padding: 0;
}

.multiselect__single,
.multiselect__tag{
    margin-top: 8px;
    margin-bottom:0;
}
.multiselect__input{
    height: 36px;
    margin: 0;
}

// cambio altura texArea para CMS
.customForm .trumbowyg-box{
    min-height: 140px;
}
.svc-flex-row.svc-full-container{
    height: calc(86*(var(--sjs-base-unit, var(--base-unit, 8px)))) !important;
    min-height: calc(86*(var(--sjs-base-unit, var(--base-unit, 8px)))) !important;
}

// botones modales - machacar el problema de los botones en modales
.modal .btn.btn-primary{
    @extend .btn-primary;
}
.modal .btn.btn-secondary{
    @extend .btn-secondary;
}