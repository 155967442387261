//
// Forms
//

// Import Dependencies
@import "forms/form-control";
@import "forms/form-select";
@import "forms/form-check";
@import "forms/input-group";
@import "forms/required";

.m-portlet--tabs > .card-header {
    position: sticky;
    top: 65px;
    background: #fff;
    z-index: 13;
}

.scaled-frame {
    zoom: 1;
    transform: scale(0.75);
    transform-origin: 0 0;
    width: 133%;
    height: 133%;
}
