.table__td-file {
    > div{
        width: 100%;
    }
    .form-group{
        margin-bottom: 0 !important;
    }
    .show-file{
        padding-top: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    .form-control.form-control-solid{
        background: transparent;
        border:none;
    }
}
